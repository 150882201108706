import React from "react";

import { TrainingPage } from "../../components/TrainingSection";

import { HocWithPreloader } from "../../../../../hocs/hocWithPreloader";
import useTrainingRequestData from "./useTrainingRequestData";

const TrainingWrapper = () => {
  const { data, setData, isLoading, error, familyId, setFamilyId, hasOwnPromocode, promocode, setPromocode } = useTrainingRequestData();

  return (
    <HocWithPreloader isLoading={isLoading} data={data} emptyText={error} withContainer>
      <div className="trainingPage">
        <TrainingPage
          data={data}
          familyProfileId={familyId}
          setFamilyId={setFamilyId}
          promocode={promocode}
          setPromocode={setPromocode}
          hasOwnPromocode={hasOwnPromocode}
          setData={setData}
        />
      </div>
    </HocWithPreloader>
  );
};

export default TrainingWrapper;
